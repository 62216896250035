export default {
    input: {
      base: 'block w-full text-sm p-2 dark:text-gray-300 leading-5 rounded-md',
      active:
        'focus:border-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-0 dark:border-gray-600  dark:bg-gray-700 dark:focus:ring-gray-300',
      disabled: 'cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800',
      valid:
        'border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200',
      invalid:
        'border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring focus:ring-red-200 dark:focus:ring-red-200',
      radio:
        'text-green-600 form-radio focus:border-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-0 dark:focus:ring-gray-300',
      checkbox:
        'text-green-600 form-checkbox focus:border-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-0 rounded dark:focus:ring-gray-300',
    }, 
    
      textarea: {
        base: 'block w-full text-sm dark:text-gray-300 rounded-md dark:bg-gray-700 focus:outline-none',
        active:
          'focus:border-green-400 border-gray-300 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-green-300',
        disabled: 'cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800',
        valid:
          'border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200',
        invalid:
          'border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring focus:ring-red-200 dark:focus:ring-red-200',
      },

      select: {
        base: 'block w-full text-sm dark:text-gray-300 focus:outline-none rounded-md',
        active:
          'focus:border-green-400 border-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:ring focus:ring-green-300 dark:focus:ring-gray-300 dark:focus:border-gray-600',
        select: 'leading-5',
        disabled: 'cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800',
        valid:
          'border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200',
        invalid:
          'border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring focus:ring-red-200 dark:focus:ring-red-200',
      },

      primary: {
        base: 'text-white bg-green-600 border border-transparent',
        active: 'active:bg-green-600 hover:bg-green-700 focus:ring focus:ring-green-300',
        disabled: 'opacity-50 cursor-not-allowed',
      },

      modal: {
        base:
          'w-full px-6 py-4 md:w-2/3 overflow-auto bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-4xl h-1/2',
      },

      card: {
        base: 'min-w-0 border-gray-300 rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden',
        default: 'bg-white dark:bg-gray-800',
      },

      tableContainer: {
        base: 'w-full border-gray-300 overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5',
      },

      // button
      button: {
        base:
          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none',
        block: 'w-full',
        size: {
          larger: 'px-10 py-4 rounded-lg',
          large: 'px-5 py-3 rounded-lg',
          regular: 'px-4 py-2 rounded-lg text-sm',
          small: 'px-3 py-1 rounded-md text-sm',
          icon: {
            larger: 'p-4 rounded-lg',
            large: 'p-3 rounded-lg',
            regular: 'p-2 rounded-lg',
            small: 'p-2 rounded-md',
          },
          pagination: 'px-3 py-1 rounded-md text-xs',
        },
        // styles applied to the SVG icon
        icon: {
          larger: 'h-5 w-5',
          large: 'h-5 w-5',
          regular: 'h-5 w-5',
          small: 'h-3 w-3',
          left: 'mr-2 -ml-1',
          right: 'ml-2 -mr-1',
        },
        primary: {
          base: 'text-white bg-green-600 border border-transparent',
          active: 'active:bg-green-600 hover:bg-green-700 focus:ring focus:ring-green-300',
          disabled: 'opacity-50 cursor-not-allowed',
        },
        outline: {
          base: 'text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none',
          active:
            'active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:ring focus:ring-gray-300',
          disabled: 'opacity-50 cursor-not-allowed bg-gray-300',
        },
        link: {
          base: 'text-gray-600 dark:text-gray-400 focus:outline-none border border-transparent',
          active:
            'active:bg-transparent hover:bg-gray-100 focus:ring focus:ring-gray-300 dark:hover:bg-gray-500 dark:hover:text-gray-300 dark:hover:bg-opacity-10',
          disabled: 'opacity-50 cursor-not-allowed',
        },
        // this is the button that lives inside the DropdownItem
        dropdownItem: {
          base:
            'inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200',
        },
      }

  }