import React, { lazy, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'


// import  useAuth from './hooks/useAuth'

const Layout = lazy(() => import('./containers/Layout'))

const Login = lazy(() => import('./pages/SignInPage'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPasswordPage = lazy(()=>import('./pages/ForgotPasswordPage'))
const VerifyUser = lazy(() => import('./pages/Verification'))
const VerifyAdmin = lazy(() => import('./pages/VerifyAdmin'))
const PaymentSuccess= lazy(()=>import('./pages/PaymentSucess'))
const Page404=lazy(() => import('./pages/404'))

//const AdminDasboard= lazy(()=> import('./components/Dashboard/AdminDashboard'))


function Routing() {

  const [authed, setAuthed] = useState(false)
  useEffect(() => {    
     setAuthed(JSON.parse(window.sessionStorage.getItem("authed")))  
  }, [authed])

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
      
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/create-account" component={CreateAccount} />
            <Route path="/forgot-password" component={ForgotPasswordPage}/>
            <Route path='/paymentsuccess' component={PaymentSuccess} />
            {/* the verify user path comes after registration - from there we  */}

            <Route path="/verify-user" component={VerifyUser} />
            <Route path="/verify-admin" component={VerifyAdmin} />
            {/* Place new routes over this */}
          
            <Route path="/app" component={Layout} />
            {authed ? 
            <Redirect exact from="/" to='/app'/>
            :
            <Redirect exact from="/" to='/login' />
            }
            <Route component={Page404}/>
          </Switch>
        
      </Router>
    </>
  )
}


function App() {

  return(  
        <Routing/>
  )
}
export default App

