const getRandomIndex = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

const avatarBgColors = [
    'bg-phema-maroon',
    'bg-phema-yellow',
    'bg-phema-green',
  ];

export const getAvatarBgColor = () => avatarBgColors[getRandomIndex(0, avatarBgColors.length-1)]