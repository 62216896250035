import React, {  useState } from "react";
import { getAvatarBgColor } from "../utils/avatarBgUtil"
import getUserRole from '../utils/userRole'


const authContext = React.createContext();
const axios = require('axios')

function useAuth() { 
    const [authed, setAuthed] = useState(false); 
    const [admin, setAdmin] = useState(false); 
// OTP verification is tied to authed state

    return {
        authed,
        admin,
        verification(formData) {
  
          const {code} = formData
          const {phone} = JSON.parse(sessionStorage.getItem("phone"))
          return new Promise(async (verify,reject) => {
     
              try {
                await axios.post(
                       `${process.env.REACT_APP_BASE_URL}/api/verify-account`,
                      {
                        phone,
                       otp_code:code
                      }
                  ).then( res=>{  
                    if (res.statusText === "OK") {
                      verify('phone number verified')
                      setAuthed(true)
                     
                      // store auth state in session storage
                      sessionStorage.setItem("authed", true);
                      sessionStorage.setItem("uploadedDocuments", false);
                      // store SERVER_BASE_URL for backup
                   //   sessionStorage.setItem("server_base_url", `${process.env.REACT_APP_BASE_URL}`);
                       sessionStorage.setItem("user", JSON.stringify({
                        token: res.data.token,
                        id: res.data.user.id,
                        firstName: res.data.user.first_name,
                        lastName:  res.data.user.last_name,
                        avatar_url: res.data.user.avatar !== null?`${process.env.REACT_APP_BASE_URL}/storage/users_avatar/${res.data.user.avatar}` : '',
                        phone: res.data.user.phone,
                        email: res.data.user.email,
                        tinNumber: res.data.user.tin_number,
                        location: res.data.user.location,
                        roleId: res.data.user.role_id,
                        role: getUserRole(res.data.user.role_id),
                        dob:res.data.user.date_of_birth,     
                        avatarBg: getAvatarBgColor()
                      }));
                    }
                  }).catch((error)=>{
                    console.log(error)
                    const {data} = error.response;
                    // destructuring the array object to get the error messages 
                    //let messageArray = Object.values(data)
                   alert(data.error)
                  return reject(data.error)  

              })
            }
              catch (error) {
                  console.error(error)
                  return alert('Please check your internet connection and try again')
              }
          
          });
      },
     
        login(formData) {
  
            const {login_details, login_password} = formData
           
            return new Promise(async (resolve,reject) => {
       
                try {
                  //use promises    await axios.post('/admin/update/value-chain/',data)
                   await axios.post(`${process.env.REACT_APP_BASE_URL}/api/login-v2`,{
                         // phone: login_country_code.replace('+', '').concat(login_phone),
                         details:login_details,
                          password: login_password
                        
                        }).then( res=>{
                         if (res.status === 200) {

                          //check if user is admin and then send to verification or 
                          const role= getUserRole(res.data.user.role_id);
                          resolve(role);
                          if(role==='admin'){
                          console.log(role)
                          sessionStorage.setItem("userRole",role );
                         
                          sessionStorage.setItem("user", JSON.stringify({
                            token: res.data.user.token,
                            id: res.data.user.id})) 
                          } else{

                        setAuthed(true)
                       
                        // store auth state in session storage
                        sessionStorage.setItem("authed", true);
                        sessionStorage.setItem("server_base_url",`${process.env.REACT_APP_BASE_URL}` );
                        sessionStorage.setItem("uploadedDocuments", res.data.user.user_document_status==="not uploaded"?false:true);
                      
                        console.log(res.data.user.user_document_status)
                        sessionStorage.setItem("user", JSON.stringify({
                          token: res.data.token,
                          id: res.data.user.id,
                          firstName: res.data.user.first_name,
                          lastName:  res.data.user.last_name,
                          avatar_url: res.data.user.avatar !== null ? `${process.env.REACT_APP_BASE_URL}/storage/users_avatar/${res.data.user.avatar}` : null,
                          phone: res.data.user.phone,
                          email: res.data.user.email,
                          tinNumber: res.data.user.tin_number,
                          location: res.data.user.location,
                          roleId: res.data.user.role_id,
                          role: getUserRole(res.data.user.role_id),
                          dob:res.data.user.date_of_birth,
                          avatarBg: getAvatarBgColor()
                        }));     
                      } 
                    }
                    }).catch((error)=>{
                      const {data} = error.response;
                      
                     // destructuring the array object to get the error messages 
                      let messageArray = Object.values(data)
                      console.log(messageArray.toString())
                     return reject(messageArray.toString())
                     // return reject(error.response)
                           
                })
               }
                catch (error) {
                    console.error(error)
                    return alert('Please check your internet connection and try again')
                }
            });

        },
        registerUser(data){
          const {first_name, last_name, email, country_code, phone, password, password_confirmation } = data;
             
           return new Promise(async (resolve,reject) => {
                 try {
                  // Set role_id
                  let role = 3
           
                   await axios.post(
                      `${process.env.REACT_APP_BASE_URL}/api/register_investor`,
                        {  
                          first_name,
                          last_name,
                          email,
                          phone: country_code.replace('+', '').concat(phone),
                          password,
                          password_confirmation,
                          role_id: role
                      }
                  
                  ).then(res=>{
                      if(res.status === 200) {
                         // setIsLoading(false)                        
                          sessionStorage.setItem("phone", JSON.stringify({
                              phone: country_code.replace('+', '').concat(phone)
                          }));                        
                        return  resolve('user registered successfully');  
                      }
                      
                  }).catch((error)=>{
                      console.log(error)
                      const {data} = error.response;
                      // destructuring the array object to get the error messages 
                     let messageArray = Object.values(data)
                     // setIsLoading(false)
                    return reject(data?.message?data?.message:messageArray.toString())                   
                  })   
              } catch (error) {               
                  return alert('Please check your internet connection and try again')
              }
          
         })
      },
      verifyAdmin(formData) {
  
        const {code} = formData
         
         
         const {token,id} = JSON.parse(sessionStorage.getItem("user"))
         console.log(id)
         const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
        return new Promise(async (verify,reject) => {
   
            try {
              await axios.post(
                     `${process.env.REACT_APP_BASE_URL}/api/2FA-verification`,
                    {
                     user_id:id,
                     otp_code:code
                    },
                    config
                ).then( res=>{  
                  if (res.statusText === "OK") {
                    verify('admin verified')
                    setAuthed(true)
                    setAdmin(true);
                     sessionStorage.setItem("admin", true);
                    // store auth state in session storage
                    sessionStorage.setItem("authed", true);
                   
                    // store SERVER_BASE_URL for backup
                 //   sessionStorage.setItem("server_base_url", `${process.env.REACT_APP_BASE_URL}`);

                     sessionStorage.setItem("user", JSON.stringify({
                      token: token,
                      id: res.data.id,
                      firstName: res.data.first_name,
                      lastName:  res.data.last_name,
                      avatar_url: res.data.avatar !== null?`${process.env.REACT_APP_BASE_URL}/storage/users_avatar/${res.data.avatar}` : '',
                      phone: res.data.phone,
                      email: res.data.email,
                      tinNumber: res.data.tin_number,
                      location: res.data.location,
                      roleId: res.data.role_id,
                      role: getUserRole(res.data.role_id),
                      dob:res.data.date_of_birth,     
                      avatarBg: getAvatarBgColor()
                    }));
                  }
                }).catch((error)=>{
                 // sessionStorage.clear();
                  console.log(error)
                  const {data} = error.response;
                  // destructuring the array object to get the error messages 
                  let messageArray = Object.values(data)
                  return reject(messageArray)  

            })
          }
            catch (error) {
                console.error(error)
                return alert('Please check your internet connection and try again')
            }
         
        });
    },
        logout() {
            return new Promise((res) => {
                setAuthed(false);
                // Clear session storage
                sessionStorage.setItem('refresh-login', true)
                sessionStorage.clear();
                
                res();
            });
        },
        initiateForgotPassword(phone) {
          return new Promise(async (initiated,reject) => {
          try {
                await axios.get(
                      `${process.env.REACT_APP_BASE_URL}/api/init/otp/${phone}`,
                         {
                          loginEmail:'test@email.com',
                          loginPassword:'test123'
                         }           
                  ).then( res=>{
                    if (res.statusText === "OK") {
                      initiated(`OTP sent to ${phone}`);
                      console.log('OTP sent') 
                    }
                  }).catch((error)=>{
                    console.log(error.response)
                   //const {error} = error.response;
                  //   // destructuring the array object to get the error messages 
                  //  let messageArray = Object.values(data)               
                  return reject(error.response)          
              })
            }
              catch (error) {
                  console.error(error)
                  return alert('Please check your internet connection and try again')
              }
         
          });
        },
      forgotPassword(formData) {
        
        const {phone,password,password_confirmation,otp_code} = formData
      
        return new Promise(async (reset,reject) => {
   
            try {
              await axios.post(
                     `${process.env.REACT_APP_BASE_URL}/api/reset/password`,
                     {phone,password,password_confirmation,otp_code}
                    
                ).then( res=>{

                  if (res.statusText === "OK") {
                    reset('password reset')
                  }
                }).catch((error)=>{
                  console.log(error.response)
                  const {data} = error.response;
                  // destructuring the array object to get the error messages 
                  let messageArray = Object.values(data)
              
                return reject(messageArray.toString())          
            })
          }
            catch (error) {
                console.error(error)
                return alert('Please check your internet connection and try again')
            }
        
        });
      }

    };
  }

  
  export function AuthProvider({children}) {
    const auth = useAuth();
  
    return (
      <authContext.Provider value={auth}>
        {children}
      </authContext.Provider>
    );
  }
  
  export default function AuthConsumer() {
    return React.useContext(authContext);
  }