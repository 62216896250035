import React, { useState, useMemo } from 'react'

// create context
export const AvatarContext = React.createContext()

export const AvatarProvider = ({children}) => {
    const [avatar, updateAvatar] = useState(null)

  
    const value = useMemo(
        () => ({
          updateAvatar,
          avatar
        }),
    
      // eslint-disable-next-line
        [avatar]
        )
        
    return <AvatarContext.Provider value={value}>{children}</AvatarContext.Provider>

    


}