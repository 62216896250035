const getUserRole = role_id => {
    switch(role_id) {
        case 1:
            return 'Farmer'
        case 2:
            return 'Input Provider'
        case 3:
            return 'Investor'
        case 4:
            return 'Vendor'
        case 5:
            return 'admin'
        default:
            return ''
    }
}

export default getUserRole