import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import { AvatarProvider } from './context/AvatarContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
import phemaTheme from './assets/phemaTheme'
import { AuthProvider } from './hooks/useAuth'
import {QueryClient,QueryClientProvider} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
const queryClient = new QueryClient()

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
 
  <SidebarProvider>
  <QueryClientProvider client={queryClient}>
    <AvatarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill usePreferences theme={phemaTheme}>
      {/* <UsersProvider>
          <FarmsProvider>
            <ValueChainsProvider> */}
        <AuthProvider>
       
            <App />
            
        </AuthProvider>
       
      </Windmill>
      </Suspense>
    </AvatarProvider>
    <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </SidebarProvider>
 ,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
